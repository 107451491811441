/*
Teal color: #16a085
*/

/* ---------------------------- */
/* BASIC SETUP*/
/* ---------------------------- */

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html,
body {
    background-color: #fff;
    color: #555555;
    font-family: "Lato", "Arial", "sans-serif";
    font-weight: 300;
    font-size: 20px;
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
}

/* ---------------------------- */
/* REUSABLE COMPONENTS*/
/* ---------------------------- */

.row {
    max-width: 1140px;
    margin: 0 auto;
}

section {
    padding: 80px 0;
}

.box {
    padding: 1%;
}

/* ----- HEADINGS -----*/

h1,
h2,
h3 {
    font-weight: 300;
    text-transform: uppercase;
}

h1 {
    margin-top: 0;
    margin-bottom: 20px;
    color: #fff;
    font-size: 240%;
    word-spacing: 4px;
    letter-spacing: 1px;
}

h2 {
    font-size: 180%;
    word-spacing: 2px;
    text-align: center;
    margin-bottom: 30px;
    letter-spacing: 1px;
}

h3 {
    font-size: 110%;
    margin-bottom: 15px;
}

h2:after {
    display: block;
    height: 2px;
    background-color: #16a085;
    content: " ";
    width: 100px;
    margin: 0 auto;
    margin-top: 30px;
}

/* ----- PARAGRAPHS -----*/

.long-copy {
    line-height: 145%;
    text-align: center;
}

.box p {
    font-size: 90%;
    line-height: 145%;
}

/* ----- ICONS -----*/

.icon-big {
    font-size: 350%;
    display: block;
    color: #16a085;
    margin-bottom: 10px;
}

/* ----- BUTTONS -----*/

.btn:link,
.btn:visited,
input[type="submit"] {
    display: inline-block;
    padding: 10px 30px;
    font-weight: 300;
    text-decoration: none;
    border-radius: 200px;
    -webkit-transition: background-color 0.2s, border 0.2s, color 0.2s;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
}

.btn-full:link,
.btn-full:visited,
input[type="submit"] {
    background-color: #16a085;
    border: 1px solid #16a085;
    color: #fff;
    margin-right: 15px;
}

.btn-ghost:link,
.btn-ghost:visited {
    border: 1px solid #16a085;
    color: #16a085;
}

.btn:hover,
.btn:active,
input[type="submit"]:hover,
input[type="submit"]:active {
    background-color: #12826c;
}

.btn-full:hover,
.btn-full:active {
    border: 1px solid #12826c;
}

.btn-ghost:hover,
.btn-ghost:active {
    border: 1px solid #12826c;
    color: #fff;
}

/* ---------------------------- */
/* HEADER*/
/* ---------------------------- */

.hero-text-box {
    position: absolute;
    width: 1140px;
    top: 35%;
    left: 20%;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

.contact-page-container {
    position: absolute;
    width: 1140px;
    top: 35%;
    left: 20%;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

.team-page-container {
    position: absolute;
    width: 1140px;
    top: 20%;
    left: 20%;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

.logo {
    height: 30px;
    float: right;
}

.logo-text {
    font-size: 15px;
    /* height: 15px; */
    float: right;
    padding: 0px;
    margin-bottom: 0px;
    margin-top: -10px;
}

.edward {
    margin: 7px 0px -8px 4px;
}

.harrison-inc {
    margin: 0 0 0 4px;
}

.navbar-background {
    background: rgba(0,0,0,.5);
}

/* Main navi */
.main-nav {
    float: right;
    list-style: none;
    margin-top: 55px;
}

.main-nav li {
    display: inline-block;
    margin-left: 40px;
}

.main-nav li a:link,
.main-nav li a:visited {
    padding: 8px 0;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 90%;
    border-bottom: 2px solid transparent;
    -webkit-transition: border-bottom 0.2s;
    transition: border-bottom 0.2s;
}

.main-nav li a:hover,
.main-nav li a:active {
    border-bottom: 2px solid #16a085;
}

/* Sticky navi */
.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 2px 2px #efefef;
    z-index: 9999;
}

.sticky .main-nav {
    margin-top: 18px;
    margin-bottom: 18px;
}

.sticky .main-nav li a:link,
.sticky .main-nav li a:visited {
    padding: 8px 0;
    color: #555;
}

.section-features .long-copy {
    margin-bottom: 30px;
}

/* ---------------------------- */
/* FORM*/
/* ---------------------------- */

.section-form {
    background-color: rgb(239, 239, 239);
}

.contact-form {
    width: 60%;
    margin: 0 auto;
}

input[type="text"],
input[type="email"],
select,
textarea {
    width: 100%;
    padding: 7px;
    border-radius: 3px;
    border: 1px solid #ccc;
}

textarea {
    height: 100px;
}

input[type="checkbox"] {
    margin: 10px 5px 10px 0;
}

.inputErrorMessage {
    font-weight: "bold";
    color: "red";
    text-align: center;
    padding-left: 50%;
}

.Call-us {
    padding: 40px;
    font-size: 25px;
    float: right;
}

/* ---------------------------- */
/* FORM*/
/* ---------------------------- */

footer {
    background-color: #333;
    padding: 50px;
    font-size: 80%;
}

.footer-nav {
    list-style: none;
    float: left;
}

.social-links {
    list-style: none;
    float: right;
}

.footer-nav li,
.social-links li {
    display: inline-block;
    margin-right: 20px;
}

.footer-nav li:last-child,
.social-links li:last-child {
    margin-right: 0;
}

.footer-nav li a:link,
.footer-nav li a:visited,
.social-links li a:link,
.social-links li a:visited {
    text-decoration: none;
    border: 0;
    color: #888;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
}

.footer-nav li a:hover,
.footer-nav li a:active {
    color: #ddd;
}

.social-links li a:link,
.social-links li a:visited {
    font-size: 160%;
}

.ion-logo-facebook,
.ion-logo-twitter,
.ion-logo-googleplus,
.ion-logo-instagram {
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
}

.ion-logo-facebook:hover {
    color: #3b5998;
}

.ion-logo-twitter:hover {
    color: #00aced;
}

.ion-logo-googleplus:hover {
    color: #dd4b39;
}

.ion-logo-instagram:hover {
    color: #517fa4;
}

footer p {
    color: #888;
    text-align: center;
    margin-top: 20px;
}

/* ---------------------------- */
/* AUTHOR*/
/* ---------------------------- */

.Scott {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    float: left;
    margin-top: 30px;
}

.author-text {
    float: left;
    margin-top: 60px;
    margin-left: 30px;
}

.Logo {
    float: right;
    margin-top: 30px;
    height: 85px;
}

.author {
    height: 60px;
}

footer {
    float: left;
    width: 100%;
}
