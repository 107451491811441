header {
    background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(rgba(0, 0, 0, 0.7)),
            to(rgba(0, 0, 0, 0.7))
        ),
        url(../assets/best-software-development-process.jpg);
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
        url(../assets/best-software-development-process.jpg);
    background-size: cover;
    background-position: center;
    height: 100vh;
    background-attachment: fixed;
}


